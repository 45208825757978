import styled, { css } from 'styled-components'

import { container, flex, flexItem, Margin, margin, media } from '../base'
import { resizeImage, StoryblokBlock } from '../services'
import { Person, FadeIn } from '../components'
import { Career, useRandomTeam } from '../features/team'

type TeamBlock = StoryblokBlock & {
  team: any // TODO
  visual_margin?: Margin
}

type Props = {
  block: TeamBlock
}

const Container = styled.div<any>`
  ${container()}
  ${(props) => props.$margin && margin(props.$margin)}
`

const Content = styled.div`
  @media (min-width: 640px) {
  }

  @media ${media.minTablet} {
    ${flex()}
  }
`

const Main = styled.div<any>`
  @media (min-width: 640px) {
  }

  @media ${media.minTablet} {
    ${flexItem(8, 2)}
    ${(props) =>
      props.$large &&
      css`
        font-size: 24px; // TODO: Use from font('body-large')
      `}
  }
`

const Items = styled.ul`
  padding: 0;
  margin: 0 0 -40px 0;
  list-style: none;

  @media (min-width: 640px) {
    margin: -22px;
    display: flex;
    flex-wrap: wrap;
  }
`

const Item = styled.li`
  margin-bottom: 40px;

  @media (min-width: 640px) {
    flex: 0 0 auto;
    flex-basis: 50%;
    max-width: 50%;
    padding: 22px;
    display: flex;
  }

  @media ${media.minNotebook} {
    flex-basis: 33.333333%;
    max-width: 33.333333%;
  }
`

export function TeamBlock({ block }: Props) {
  let members: any = useRandomTeam(block.team)

  // Add career placeholder
  members = [...members.slice(0, 1), 'PLACEHOLDER', ...members.slice(1)]

  return (
    <Container $margin={block.visual_margin}>
      <Content>
        <Main>
          <Items>
            {members.map((item: any) => {
              if (item === 'PLACEHOLDER') {
                return (
                  <Item key="placeholder">
                    <FadeIn>
                      <Career />
                    </FadeIn>
                  </Item>
                )
              } else {
                const resizedPhoto = resizeImage(item.content.photo, 640, 880)
                return (
                  <Item key={item.id}>
                    <FadeIn>
                      <Person showCV={true} showMail={false} person={{ ...item, photo: resizedPhoto }} />
                    </FadeIn>
                  </Item>
                )
              }
            })}
          </Items>
        </Main>
      </Content>
    </Container>
  )
}
