import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { container, flex, flexItem, media } from '../../base'
import { Link } from '../../components'

const Wrapper = styled.footer`
  border-bottom: 16px solid var(--color-primary);
  overflow: hidden;
`

const Container = styled.div`
  ${container()}
`

const Content = styled.div`
  padding-top: 45px;
  padding-bottom: 35px;

  @media ${media.minTablet} {
    ${flex()}
    align-items: flex-end;
    padding-top: 94px;
    padding-bottom: 70px;
  }
`

const Icon = styled.div`
  margin-bottom: 30px;

  @media ${media.minTablet} {
    ${flexItem(2)}
    margin-bottom: 0;
  }

  svg {
    display: block;
    height: 66px;
    position: relative;
    bottom: 4px;
  }
`

const Main = styled.div`
  @media ${media.minTablet} {
    ${flexItem(6)}
  }

  a {
    text-decoration: none;
  }
`

const Meta = styled.div`
  margin-top: 30px;

  ul {
    padding: 0;
    margin: 0 -24px;
    list-style: none;
    display: flex;
  }

  li {
    padding: 0 24px;
  }
`

const StyledLink = styled(Link)`
  color: rgba(54, 86, 117, 0.4);
  text-decoration: none;

  &:hover {
    color: var(--color-primary);
  }
`

const Spacer = styled.span`
  font-size: 0.8em;
  margin: 0 1px;
  position: relative;
  top: 0px;
`

export function Footer() {
  const intl = useIntl()

  return (
    <Wrapper>
      <Container>
        <Content>
          <Icon>
            <Link href="/" aria-label="Startseite">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 201 109">
                <g transform="translate(.2 .4)" fillRule="nonzero" fill="none">
                  <path
                    d="M40.4 108.1L0 0h22.5c3.2 0 6 2 7.1 4.9l23.2 61.5-12.4 41.7zm49.8 0L49.8 0h22.4c3.2 0 6 2 7.1 4.9l23.1 61.6-12.2 41.6z"
                    fill="#365675"
                  />
                  <circle fill="#48A851" cx="183.3" cy="16.8" r="16.7" />
                  <path d="M150.1 0h-40.3v33.4l22.6-22.6v95.9h25.3V7.6c0-4.2-3.4-7.6-7.6-7.6z" fill="#365675" />
                </g>
              </svg>
            </Link>
          </Icon>
          <Main>
            <strong>WeTrust Group</strong>
            <br />
            Poststrasse 2 <Spacer>•</Spacer> 9494 Schaan <Spacer>•</Spacer> Liechtenstein
            <br />
            <a href="tel:+4232333308">{intl.formatMessage({ id: 'phone.abbr' })} +423 233 33 08</a> <Spacer>•</Spacer>{' '}
            <a href="mailto:office@wetrust.li">office@wetrust.li</a>
          </Main>
          <Meta>
            <ul>
              <li>
                <StyledLink href="/impressum">{intl.formatMessage({ id: 'imprint' })}</StyledLink>
              </li>
              <li>
                <StyledLink href="/datenschutz">{intl.formatMessage({ id: 'privacy-policy' })}</StyledLink>
              </li>
            </ul>
          </Meta>
        </Content>
      </Container>
    </Wrapper>
  )
}
