import styled from 'styled-components'
import GoogleMapReact, { BootstrapURLKeys } from 'google-map-react'

type extendedKeys = BootstrapURLKeys & { mapIds: string[] }

const API_KEY: string = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || ''
const MAP_ID = '76cb3e108f8df33c'
const MAP_CENTER = { lat: 47.16801015529492, lng: 9.510282592959916 }
const MAP_MARKER = { lat: 47.16801015529492, lng: 9.510282592959916 }

const keys: extendedKeys = {
  key: API_KEY,
  mapIds: [MAP_ID],
}

const options: any = {
  mapId: MAP_ID,
}

const Wrapper = styled.div`
  height: 656px;
`

const Marker = styled.div<any>`
  width: 54px;
  transform: translate(-50%, -100%);

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

export function Map() {
  return (
    <Wrapper>
      <GoogleMapReact bootstrapURLKeys={keys} defaultZoom={16} defaultCenter={MAP_CENTER} options={options}>
        <Marker lat={MAP_MARKER.lat} lng={MAP_MARKER.lng}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.287 54.287">
            <path d="M0 0h54.287v54.287H0z" fill="none" />
            <path
              d="M27.145 4.524a15.822 15.822 0 00-15.834 15.834c0 11.875 15.834 29.405 15.834 29.405s15.834-17.53 15.834-29.406A15.822 15.822 0 0027.145 4.524zm-11.31 15.834a11.31 11.31 0 1122.62 0c0 6.514-6.514 16.264-11.31 22.348-4.705-6.04-11.31-15.902-11.31-22.348z"
              fill="#48a851"
            />
            <circle cx="5.655" cy="5.655" r="5.655" transform="translate(21.488 14.703)" fill="#48a851" />
          </svg>
        </Marker>
      </GoogleMapReact>
    </Wrapper>
  )
}
